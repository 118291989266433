import React, { useEffect, useState, Suspense, lazy } from 'react';
import { useTranslation } from 'react-i18next';

const BlacklistUrlsLazy = React.lazy(() => import('./boxToBlackListURL'));
const CurrencyToggleLazy = React.lazy(() => import('./CurrencyToggle'));
const LanguageSelectorLazy = React.lazy(() => import('./LanguageSelector'));
const UpdateNewsComponentLazy = React.lazy(() => import('./lastNews'));
const GoogleSheetDataComponentLazy = React.lazy(() => import('./googleAPI'));

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`) 
    ? `https://${stagingKey}.peaxel.me` 
    : 'https://peaxel.me';

  const socialLinks = [
    { href: "https://www.instagram.com/peaxel_game/", icon: "fa-instagram" },
    { href: "https://x.com/Peaxel8888", icon: "fa-x-twitter" },
    { href: "https://discord.com/invite/6tbvgYgJ", icon: "fa-discord" },
    { href: "https://www.youtube.com/@Peaxel.8888", icon: "fa-youtube" },
    { href: "https://www.linkedin.com/company/peaxel/", icon: "fa-linkedin-in" },
    { href: "https://telegram.me/peaxel_game", icon: "fa-telegram" },
    { href: "https://medium.com/@peaxel", icon: "fa-medium" }
  ];

  const currentWalletAddress = localStorage.getItem('walletAddress');

  const footerLinks = [
    [
      { href: `${linkUrl}/peaxel-terms-and-conditions/`, text: t('termsAndConditions') },
      { href: `${linkUrl}/peaxel-privacy-policy/`, text: t('privacyPolicy') },
      { href: `${linkUrl}/peaxel-privacy-policy/`, text: t('cookiePolicy') }
    ],
    [
      { href: `${linkUrl}`, text: t('home') },
      { href: `${linkUrl}/game/`, text: t('enterTheGame') },
      { href: `${linkUrl}/blog-slow-down/`, text: t('blog') }
    ],
    [
      { href: `${linkUrl}/advantages-for-talents-on-peaxel/`, text: t('talentAdvantages') }
    ]
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 1000); // 1000ms = 1 second

    return () => clearTimeout(timer);
  }, []);

  if (!isVisible) {
    return null; // or return a loading placeholder if you prefer
  }

  return (
    <div className="footer-container">
      <div className="footer-full-row-top">
        {socialLinks.map((link, index) => (
          <span key={index} className="socialPxl">
            <a href={link.href}><i className={`fa-brands ${link.icon}`}></i></a>
          </span>
        ))}
      </div>
      <div className="footer-columns">
        {footerLinks.map((column, columnIndex) => (
          <div key={columnIndex} className="footer-column">
            <ul>
              {column.map((link, linkIndex) => (
                <li key={linkIndex}><a href={link.href}>{link.text}</a></li>
              ))}
            </ul>
          </div>
        ))}
        <div className="footer-column"></div>
      </div>
      <div className="footer-full-row">
        <span>
          <Suspense fallback={<div>{t('loading')}</div>}>
            <CurrencyToggleLazy />
          </Suspense>
        </span>
        <span>&nbsp;&nbsp;</span>
        <span>
          <Suspense fallback={<div>{t('loading')}</div>}>
            <LanguageSelectorLazy />
          </Suspense>



        </span>
      </div>
      {currentWalletAddress === '0xbdc8186faa4c50061948edfdfc4a5b534c42a45d' && (
<div className="footer-backend">
<h2>BACKEND</h2>
<hr />
<h3>SCRAPPING</h3>
<Suspense fallback={<div>{t('loading')}</div>}>
<GoogleSheetDataComponentLazy />
<hr />
<h3>NEWS</h3>
<UpdateNewsComponentLazy />
<hr />
<h3>BLACKLIST NEWS WEBSITE</h3>
<BlacklistUrlsLazy />
</Suspense>
        </div>
      )}
    </div>
  );
};

export default Footer;